import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Define a type for the slice state

export const ProjectType = {
  USER: 'USER',
  TEAM: 'TEAM',
};

export type ProjectInput = {
  name: string;
  accountId: string | undefined;
  teamId?: string | undefined;
};

export type Folder = {
  path: string
  key: string
  name: string
  icon: string
  resourceFiles: CommonResource[]
  createdTime: string
}

export type ResourceFile = {
  path: string
  key: string
  name: string
  extension: string
  icon: string
  createdTime: string
}

export type CommonResource = Folder | ResourceFile

export type Project = {
  projectId: string;
  name: string;
  type: string;
  createdAt: string;
  sharedBy?: string;
  sharedToTeamId?: string;
  teamId?: string;
  createdBy?: string;
  serviceType?: string;
  commonTestResources?: CommonResource[],
};

interface ProjectDataState {
  projects: Array<Project>;
  selectedProject?: Project;
}

// Define the initial state using that type
const initialState: ProjectDataState = {
  projects: [],
  selectedProject: undefined,
};

export const projectDataSlice = createSlice({
  name: 'projectData',
  initialState,
  reducers: {
    setProjects: (state, action: PayloadAction<Array<Project>>) => {
      state.projects = action.payload;
    },
    setSelectedProject: (state, action: PayloadAction<Project | undefined>) => {
      state.selectedProject = action.payload;
    },
  },
});

export const {
  setProjects, setSelectedProject,
} = projectDataSlice.actions;

export default projectDataSlice.reducer;
