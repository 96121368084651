import { Col, Row } from 'antd';
import React from 'react';
import Loading from '../../components/Loading';
import ResultLogsItem from './MonitoringItemResultLogListItem';

interface propType {
  isLoading: boolean
  job: any
  testRuns: any[]
  isShared: boolean
  isSearching: boolean
}

const ResultLogs = ({
  isLoading = true, job, testRuns, isShared, isSearching,
}: propType) => (
  <div className="fp_list" style={{ marginTop: '0px' }}>
    <Row justify="start" className="fp_list_title_new">
      <Col span={7} className="column-align right-border"><p>Result</p></Col>
      <Col span={4} className="column-align right-border"><p>Date</p></Col>
      <Col span={3} className="column-align right-border"><p>Time</p></Col>
      <Col span={6} className="column-align right-border"><p>Result</p></Col>
      <Col span={4} className="column-align"><p>Logs</p></Col>
    </Row>
    {isLoading ? <Loading plain size="large" /> : (isSearching ? testRuns
      : testRuns
        .sort((a: any, b: any) => new Date(b.placedAt).getTime() - new Date(a.placedAt).getTime())
    ).map((testRun: any, index: number) => (
      <ResultLogsItem
        testRun={testRun}
        job={job}
        index={index + 1}
        isShared={isShared}
      />
    )) }
  </div>
);

export default ResultLogs;
