import { API } from 'aws-amplify';
import { APIS } from '../../utils/apiUtils';
import { notifyError } from '../../utils/notification';

export const getAllMonitorJobs = async (accountId: string) => {
  try {
    const response = await API.get(APIS.MONITOR_API, '/get-all-monitor-jobs', { queryStringParameters: { accountId } });
    return response;
  } catch (error: any) {
    console.log('getAllMonitorJobs function failed:', error.message);
    return [];
  }
};

export const getAnalytics = async (
  shareId: string | undefined,
  accountId: string,
  monitorId: string,
) => {
  try {
    const response = await API.get(APIS.MONITOR_API, shareId ? '/get-shared-analytics' : '/get-analytics', { queryStringParameters: { shareId, accountId, monitorId } });
    return response;
  } catch (error: any) {
    console.log('getAnalytics function failed:', error.message);
    notifyError('Failed to fetch analytics data.');
    return undefined;
  }
};

export const getFailedTestRuns = async (
  shareId: string | undefined,
  accountId: string,
  monitorId: string,
  pageSize: number,
  currentPage: number,
) => {
  try {
    const response = await API.get(
      APIS.MONITOR_API,
      shareId ? '/shared-analytics/get-failed-test-runs' : '/analytics/get-failed-test-runs', {
        queryStringParameters: {
          shareId, accountId, monitorId, pageSize, currentPage,
        },
      },
    );
    return response;
  } catch (error: any) {
    console.log('getFailedTestRuns function failed:', error.message);
    notifyError('Failed to fetch failed test runs.');
    return undefined;
  }
};

export const getFrontendLogs = async (
  shareId: string | undefined,
  accountId: string,
  monitorId: string,
  pageSize: number,
  currentPage: number,
  selectedBrowser?: string | undefined,
) => {
  try {
    const response = await API.get(APIS.MONITOR_API, shareId ? '/shared-analytics/get-frontend-logs' : '/analytics/get-frontend-logs', {
      queryStringParameters: {
        shareId, accountId, monitorId, pageSize, currentPage, selectedBrowser,
      },
    });
    return response;
  } catch (error: any) {
    console.log('getFrontendLogs function failed:', error.message);
    notifyError('Failed to fetch frontend logs.');
    return undefined;
  }
};

export const updateMonitorJob = (queryStringParameters: any, body: any) => API.post(APIS.MONITOR_API, '/update-monitor', { queryStringParameters, body });

export const updateMonitorStatus = (queryStringParameters: any) => API.get(APIS.MONITOR_API, '/update-monitor-status', { queryStringParameters });

export const deleteMonitorFunc = (queryStringParameters: any) => API.get(APIS.MONITOR_API, '/delete-monitor', { queryStringParameters });

export const updateTestRunErrorMessage = (body: any) => API.post(APIS.MONITOR_API, '/analytics/update-failed-test-run', { body });
