import React, { useState } from 'react';
import {
  Button,
  Col, Row, Tooltip,
  notification,
} from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { MoreOutlined } from '@ant-design/icons';
import { renderText } from '../../utils/commonUtils';
import { ROUTES } from '../../constants';
import { MONITOR_STATUS } from '../../types/common';
import { TrashCanIcon } from '../../components';
import DeleteConfirmationModal from '../testExecutionSuit/modals/DeleteConfirmationModal';
import { notifyInProgress, notifySuccess, notifyError } from '../../utils/notification';
import { deleteMonitorFunc } from './action';
import { ENABLE_MONITOR_MODE } from '../../configs/app-config';

interface propType {
  job: any
  handleRefresh: Function
}

const renderStatus = (status: string) => {
  switch (status) {
    case MONITOR_STATUS.COMPLETE:
      return <p className="customize-text-completed">Complete</p>;
    case MONITOR_STATUS.IN_PROGRESS:
      return <p className="customize-text-inprogress">In Progress</p>;
    case MONITOR_STATUS.PAUSED:
      return <p className="customize-text-paused">Paused</p>;
    default:
      return <></>;
  }
};

const MonitoringItemListViewItem = ({ job, handleRefresh }: propType) => {
  const history = useHistory();

  const [deleteMonitor, setDeleteMonitor] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const handleViewButton = () => {
    history.push(`${ROUTES.MONITOR}/${job.monitorId}`);
  };

  const handleButtonAction = () => {
    if (deleteMonitor) {
      setModalOpen(true);
    } else {
      handleViewButton();
    }
  };

  const deleteMonitorJob = async () => {
    try {
      notifyInProgress('Deleting monitor');
      setModalOpen(false);
      await deleteMonitorFunc({ accountId: job.accountId, monitorId: job.monitorId });
      handleRefresh();
      notification.destroy();
      notifySuccess('Monitor deleted successfully');
    } catch (err: any) {
      console.log('err :', err.message);
      notification.destroy();
      notifyError('Failed to delete the monitor');
    }
  };

  return (
    <div className="fp_test_exec_list_item" key={JSON.stringify(job)}>
      <Row justify="start" className="fp_list_item_new">
        <Col span={ENABLE_MONITOR_MODE ? 12 : 7} className="right-border">
          <Tooltip title={job.name} className="sm-tooltip-container">
            <p>{renderText(job.name, 60)}</p>
          </Tooltip>
        </Col>
        {!ENABLE_MONITOR_MODE && (
        <Col span={5} className="fp_list_item_module column-align right-border">
          <Tooltip title={`${job.projectName ? job.projectName : 'none'} / ${job.moduleName}`}><div>{job.projectName}</div></Tooltip>
          <span>/</span>
          <Tooltip title={`${job.projectName ? job.projectName : 'none'} / ${job.moduleName}`}><div>{job.moduleName}</div></Tooltip>
        </Col>
        )}
        <Col span={3} className="column-align right-border"><p>{`Every ${job.repeatFrequency}`}</p></Col>
        <Col span={4} className="column-align right-border"><p>{job?.lastRun ? moment(job?.lastRun).format('lll') : '-'}</p></Col>
        <Col span={2} className="column-align right-border">{renderStatus(job.status)}</Col>
        <Col span={3} style={{ display: 'flex' }} className="column-align">
          <Row className="fp_test_exec_list_item_action">
            <Col>
              <Button style={{ fontSize: '16px' }} onClick={handleButtonAction} type="primary" icon={deleteMonitor && <TrashCanIcon />} className={`fp_btn fp_btn-primary ${deleteMonitor ? 'btn_delete' : 'fp_btn-view'}`}>{!deleteMonitor ? 'View' : 'Delete monitor'}</Button>
            </Col>
            {!ENABLE_MONITOR_MODE && (
            <Col>
              <p><MoreOutlined onClick={() => setDeleteMonitor(!deleteMonitor)} style={{ fontSize: '20px' }} /></p>
            </Col>
            )}
          </Row>
        </Col>
      </Row>
      <DeleteConfirmationModal
        title="Are you sure you want to delete this monitor?"
        deleteButtonText="Delete Monitor"
        isModalOpen={isModalOpen}
        handleCancel={() => setModalOpen(false)}
        handleDelete={deleteMonitorJob}
        isLoading={false}
      />
    </div>
  );
};
export default MonitoringItemListViewItem;
