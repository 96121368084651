/* eslint-disable no-unused-vars */
import {
  Modal,
  Row,
  Col,
  Button,
  Pagination,
  PaginationProps,
  Tooltip,
} from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import FrontendLogList from './FrontendLogList';
import BrowserSelector from '../../components/BrowserSelector';
import { getFrontendLogs } from '../../action';
import { useAppSelector } from '../../../../redux/hooks';

interface propType {
  open: boolean
  onClose: Function
  selectedEDB: any[]
}

const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return <a className="nav-buttons">Previous</a>;
  }
  if (type === 'next') {
    return <a className="nav-buttons">Next</a>;
  }
  return originalElement;
};

const FrontendLogsModal = ({
  open,
  selectedEDB,
  onClose,
}: propType) => {
  const [defaultKey, setDefaultKey] = useState<string>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [selectedLog, setSelectedLog] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [payload, setPayload] = useState<any>();
  const pageSize = 10;

  const accountId: string = useAppSelector(
    ({ userData }: any) => userData?.selectedAccount?.accountId,
  );
  const { monitorId, shareId } = useParams<any>();

  const onClearClose = () => {
    onClose(false);
  };

  const fetchData = async (page: number, browser: string | undefined = undefined) => {
    setLoading(true);
    const response = await getFrontendLogs(shareId, accountId, monitorId, pageSize, page, browser);
    setPayload(response);
    if (!defaultKey) {
      setDefaultKey(response?.selectedBrowser);
    }
    setLoading(false);
  };

  const selectBrowser = (value: string) => fetchData(1, value);

  const selectedExecution = (log: any) => setSelectedLog(log);

  const getColor = (type: string) => {
    switch (type) {
      case 'warn':
        return 'var(--Indicator-warning-Light, #FDF38C)';
      case 'warning':
        return 'var(--Indicator-warning-Light, #FDF38C)';
      case 'info':
        return 'var(--Indicator-Info-light, #73ADFA)';
      case 'log':
        return 'var(--Indicator-Info-light, #73ADFA)';
      case 'error':
        return 'var(--Indicator-Danger-Light, #E46962)';
      default:
        return 'white';
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    fetchData(page, payload?.selectedBrowser);
  };

  useEffect(() => {
    if (open) fetchData(currentPage);
  }, [open]);

  return (
    <>
      <Modal
        className="max-user-modal fp-modal fp-modal-primary close-modal-icon modal-max-width"
        width={600}
        open={open}
        closable
        maskClosable={false}
        onCancel={onClearClose}
        style={{ textAlign: 'center' }}
        footer={null}
      >
        {selectedLog ? (
          <>
            <h3 style={{ color: 'white', marginBottom: 20 }}>{moment(selectedLog?.executedTime).format('MMM DD, YYYY hh:mm A')}</h3>
            <Button
              style={{
                alignItems: 'center',
                border: '1px solid #844EE0',
                background: 'var(--Solid-Primary, #844EE0)',
                boxShadow: '0px 4px 4px 0px rgba(67, 0, 52, 0.05)',
                borderRadius: 8,
                marginBottom: 10,
              }}
              onClick={() => setSelectedLog(undefined)}
            >
              Back
            </Button>
            <div style={{
              borderRadius: '12px',
              border: '1px solid var(--Background-Inputs, rgba(255, 255, 255, 0.20))',
              background: 'rgba(20, 20, 20, 0.60)',
              backdropFilter: 'blur(18px)',
              padding: '12px 15px',
            }}
            >
              {selectedLog?.data?.map((consoleLog: any) => (
                <Row key={JSON.stringify(consoleLog)}>

                  <Col span={12}>
                    <Tooltip title={consoleLog?.text}>
                      <p style={{ color: getColor(consoleLog.type) }}>{consoleLog?.text?.length > 60 ? `${consoleLog?.text?.slice(0, 60)}...` : consoleLog?.text}</p>
                    </Tooltip>
                  </Col>

                  <Col span={12}>
                    <a href={consoleLog?.location?.url}>{consoleLog.location?.url?.length > 60 ? `${consoleLog.location?.url.slice(0, 60)}...` : consoleLog.location?.url}</a>
                  </Col>
                </Row>
              ))}
            </div>
          </>
        ) : (
          <>
            <h3 style={{ color: 'white', marginBottom: 20 }}>Frontend error logs</h3>
            <Row justify="end" style={{ marginBottom: '10px' }}>
              {(selectedEDB.length && defaultKey) && (
              <BrowserSelector
                selectBrowser={selectBrowser}
                defaultKey={defaultKey}
                browserDataObject={selectedEDB
                  ?.reduce((acc: any, val: any) => ({ ...acc, [val]: val }), {})}
              />
              )}
            </Row>
            <FrontendLogList
              isLoading={isLoading}
              frontendLogs={payload?.consoleLogs || []}
              selectedExecution={selectedExecution}
            />
          </>
        )}
        <Row>
          <Col span={24} style={{ textAlign: 'right' }} className="pagination-container">
            {payload?.totalItemCount && !selectedLog && (
            <Pagination
              defaultCurrent={1}
              itemRender={itemRender}
              pageSize={pageSize}
              total={payload?.totalItemCount}
              current={currentPage}
              onChange={handlePageChange}
            />
            )}
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default FrontendLogsModal;
