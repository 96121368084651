import React, { useEffect, useState } from 'react';
import {
  Button, Col, Row,
} from 'antd';
import moment from 'moment';
import StatusView from '../testcases/result/components/StatusView';
import Loading from '../../components/Loading';
import IDETabMenu from '../testExecutionSuit/components/IDETabMenu';
import { getExecution } from '../testcaseCreation/action';
import { EXECUTION_RESULT } from '../../constants';

// const { Title } = Typography;

const ResultLogs = ({
  testRun, index, job, isShared,
}: any) => {
  const [viewResults, setViewResults] = useState<boolean>(false);
  const [executionData, setExecutionData] = useState<any>();

  const toggleView = () => setViewResults(!viewResults);

  const fetchData = async () => {
    setExecutionData(undefined);
    const data = await getExecution(job.testcaseId, testRun.executionId, job.monitorId);
    setExecutionData(data);
  };

  useEffect(() => {
    if (viewResults) {
      fetchData();
    }
  }, [viewResults]);

  return (
    <div className="fp_test_exec_list_item" key={JSON.stringify(testRun)}>
      <Row className="fp_list_item_new">
        <Col span={7} className="right-border"><p>{testRun.resultId || `Result id #${index}`}</p></Col>
        <Col span={4} className="right-border column-align">
          <p>{moment(testRun.placedAt).format('DD/MM/YYYY')}</p>
        </Col>
        <Col span={3} className="column-align right-border">
          <p>{moment(testRun.placedAt).format('h:mm a')}</p>
        </Col>
        <Col span={6} className="column-align right-border" style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
          <p>
            <StatusView
              status={(testRun.status !== EXECUTION_RESULT.COMPLETED
                || testRun?.log?.suites?.length) ? testRun.status : testRun?.statistics?.status}
              executionResults={testRun?.log?.suites[0]?.specs[0]?.tests}
              passPercentage={testRun?.passPercentage}
            />
          </p>
        </Col>
        <Col span={4} style={{ display: 'flex' }} className="column-align">
          <Button
            type="primary"
            className="fp_btn fp_btn-view"
            style={{ fontSize: '16px' }}
            onClick={toggleView}
            disabled={
              [EXECUTION_RESULT.PROVISIONING, EXECUTION_RESULT.RUNNING]
                .includes(testRun?.status)
            }
          >
            {viewResults ? 'View Less' : 'View'}
          </Button>
        </Col>

        {viewResults && (
        <Row style={{ width: '100%' }}>
          {!executionData ? <Loading plain /> : (
            <div style={{ padding: 20, width: '100%' }}>
              <IDETabMenu
                executionData={executionData}
                script={testRun?.automationScript}
                handleOnChange={() => {}}
                disableEditMode
                isShared={isShared}
              />
            </div>
          )}
        </Row>
        )}
      </Row>
    </div>
  );
};
export default ResultLogs;
