import moment from 'moment';
import { Projects } from '../types/common';

export const getFormattedDateTime = (isoString: string, format: string = 'MMM DD, YYYY hh:mm A') => moment(isoString).format(format).toString();

export const wait = (time: number) => new Promise((resolve) => setTimeout(() => resolve(''), time));

/**
 * Function to sort an array of objects by the createdAt attribute
 * Objects with later dates will come before objects with earlier dates
 *
 * @param array - The array of objects to sort
 * @returns - The input array, sorted in place
 */
export const sortByCreateAtTime = (array: any[]): any[] => array.sort((a, b) => {
  // Convert the createdAt strings to Date objects
  const dateA = new Date(a.createdAt);
  const dateB = new Date(b.createdAt);

  // Subtract the timestamps (milliseconds since Epoch)
  // to determine the relative order of the two objects
  // Since dateB is subtracted from dateA, later dates will come first
  return dateB.getTime() - dateA.getTime();
});

/**
 * Converts the testData object to an array format
 * by extracting values and mapping them to a new object structure.
 *
 * @param testData - The testData object to be formatted
 * @param requireKey - Optional flag to indicate whether to include a 'key' property
 *                    in the formatted objects
 * @returns - An array containing the formatted testData objects
 */
export const formateTestDataToArray = (testData: any, requireKey: boolean = false): any[] => Object
  .values(testData)
  .map((item: any, index) => ({
    testDataType: item?.testDataType,
    testData: item?.testData,
    assetTag: item?.assetTag,
    key: requireKey ? index + 1 : undefined,
  }));

/**
Capitalizes the first letter of a given string.
@param s - The string to be capitalized
@returns - The capitalized string
*/
export const capitalize = (s: string): string => {
  const sT: string = s?.toLowerCase();
  return sT && sT[0]?.toUpperCase() + sT?.slice(1);
};

export const mergeTestBlocks = (testBlock1: string, testBlock2: string): string => {
  const mergedTestBlock = testBlock2.replace(/(test\('[^']+',\s*async\s*\(\s*\{\s*page\s*\}\s*\)\s*=>\s*{)/, (_, testHeader) => {
    const indentedTest1Content = testBlock1.replace(/^/gm, ''); // Indent each line with 6 spaces
    return `${testHeader}\n${indentedTest1Content.trim()}\n`;
  });

  return mergedTestBlock;
};

export const extractTestBlock = (string: string): string => {
  const testBlockPattern = /test\('[^']+', async \(\{ page \}\) => {\s*([\s\S]*?)\n\s*}\);/;
  const testBlockMatch = string.match(testBlockPattern);

  if (testBlockMatch) {
    return testBlockMatch[1].trim();
  }
  throw new Error('Failed to extract the test block from the script.');
};

export const extractDeviceNames = (devicesList: string[]): string[] => {
  const extractedNames: string[] = [];
  devicesList.forEach((device: string) => {
    const parts = device.split('_');
    if (parts.length > 0) {
      extractedNames.push(parts[0]);
    }
  });

  return extractedNames;
};

export const setTreeCheckableForLevel = (data: Projects, levelToMakeCheckable: number) => {
  const newData = { ...data };
  if (levelToMakeCheckable === 0) {
    newData.checkable = true;
  } else {
    newData.checkable = false;
  }

  if (newData.children && newData.children.length > 0) {
    newData.children = newData.children
      .map((child: any) => setTreeCheckableForLevel(child, levelToMakeCheckable - 1));
  }

  return newData;
};

export const renderText = (sentence: string, length: number = 15): string => {
  if (sentence.length > length) {
    return `${sentence.slice(0, length)}...`;
  }
  return sentence;
};

export const toCamelCase = (inputString: string): string => {
  // Remove non-alphanumeric characters and split the string into words
  const words = inputString.replace(/[^a-zA-Z0-9]/g, ' ').split(' ');

  // Convert the first letter of each word to uppercase (except the first word)
  for (let i = 1; i < words.length; i += 1) {
    words[i] = words[i][0].toUpperCase() + words[i].substring(1);
  }

  return words.join('');
};

const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const convertVariableToString = (inputString: string) => {
  const words = inputString.split(/(?=[A-Z])/);

  for (let i = 0; i < words.length; i += 1) {
    words[i] = capitalizeFirstLetter(words[i]);
  }

  return words.join(' ');
};
