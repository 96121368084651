import React, { ChangeEventHandler } from 'react';
import { Form } from 'antd';
import { TextEditor } from '../../../../../components';
import { SCRIPT_TYPE } from '../../../../../types/common';

interface EditorProps {
  disabled?: boolean
  content: string
  language?: string
  form: any
  handleOnChange: ChangeEventHandler
  selectedScript: string
}

const EditorIDE = ({
  form, disabled = false, content, language = 'js', handleOnChange, selectedScript,
}: EditorProps) => (
  <Form layout="vertical" form={form} className="fp_frm-ant-form-item">
    <Form.Item
      name={selectedScript === SCRIPT_TYPE.BDD ? 'bdd' : 'automationScript'}
      style={{
        marginBottom: 10,
      }}
      rules={[{ required: true, message: `Please enter ${selectedScript === SCRIPT_TYPE.BDD ? 'BDD script' : 'automation script'}` }]}
    >
      <TextEditor
        value={content}
        language={language}
        placeholder={`Please enter ${selectedScript === SCRIPT_TYPE.BDD ? 'BDD script' : 'automation script'}.`}
        onChange={handleOnChange}
        padding={15}
        style={{ minHeight: 600 }}
        disabled={disabled}
      />
    </Form.Item>
  </Form>
);

export default EditorIDE;
