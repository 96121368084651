import { API } from 'aws-amplify';
import { notification } from 'antd';
import { APIS } from '../../utils/apiUtils';
import { notifyError } from '../../utils/notification';

export const createEditTestSuite = async (testSuite: any): Promise<Boolean> => {
  try {
    return await API.post(APIS.TEST_SUITE_API, '/create', { body: testSuite });
  } catch (error) {
    return false;
  }
};

export const getTestSuites = async (accountId: string): Promise<any> => {
  try {
    const { testSuites } = await API.get(APIS.TEST_SUITE_API, '/get-all', { queryStringParameters: { accountId } });
    return testSuites;
  } catch (error) {
    return [];
  }
};

export const getTestSuite = async (accountId: string, testSuiteId: string): Promise<any[]> => {
  try {
    const { testSuite } = await API.get(APIS.TEST_SUITE_API, '/get', { queryStringParameters: { accountId, testSuiteId } });
    return testSuite;
  } catch (error) {
    return [];
  }
};

export const getTestCaseData = async (accountId: string): Promise<any[]> => {
  try {
    const { projects } = await API.get(APIS.TEST_SUITE_API, '/get-test-case-data', { queryStringParameters: { accountId } });
    return projects;
  } catch (error) {
    return [];
  }
};

export const deleteTestSuite = async (accountId: string, testSuiteId: string): Promise<Boolean> => {
  try {
    return await API.get(APIS.TEST_SUITE_API, '/delete', { queryStringParameters: { accountId, testSuiteId } });
  } catch (error) {
    return false;
  }
};

export const deleteTestCaseFromSuite = async (
  accountId: string, testSuiteId: string, testcaseId: string,
): Promise<Boolean> => {
  try {
    return await API.get(APIS.TEST_SUITE_API, '/testcase/delete', { queryStringParameters: { accountId, testSuiteId, testcaseId } });
  } catch (error) {
    return false;
  }
};

export const runTestSuite = async (params: any): Promise<boolean> => {
  try {
    await API.post(APIS.EXECUTION_API, '/place-execution', { body: params });
    return true;
  } catch (error: any) {
    console.log(error.message);
    notification.destroy();
    notifyError('Failed to run test suite');
    return false;
  }
};

export const getTestCase = async (
  accountId: string, testSuiteId: string, testcaseId: string,
): Promise<any> => {
  try {
    return await API.get(APIS.TEST_SUITE_API, '/testcase/get', { queryStringParameters: { accountId, testSuiteId, testcaseId } });
  } catch (error: any) {
    console.log(error.message);
    return {};
  }
};

export const updateAutomationScript = async (
  requirementId: string, testcaseId: string, automationScript: string,
): Promise<boolean> => {
  try {
    return await API.post(APIS.TEST_SUITE_API, '/testcase/update-automation-script', { queryStringParameters: { requirementId, testcaseId }, body: { automationScript } });
  } catch (error: any) {
    console.log(error.message);
    return false;
  }
};

export const addTestCaseToTestSuite = async (
  accountId: string, testSuites: any, testcases: any,
): Promise<boolean> => {
  try {
    return await API.post(APIS.TEST_SUITE_API, '/add-test-case-to-test-suite', { queryStringParameters: { accountId }, body: { testSuites, testcases } });
  } catch (error: any) {
    console.log(error.message);
    return false;
  }
};
