import React from 'react';
import {
  Button,
  Col, Row,
} from 'antd';
import moment from 'moment';

const FrontendLogsListItem = ({ log, selectedExecution }: {
  log: any, selectedExecution: Function
}) => {
  const renderLogCountTag = (type: string, count: number) => {
    let text;
    let background;
    switch (type) {
      case 'infoCount':
        text = 'Info';
        background = 'var(--Indicator-info, #0D6EFD)';
        break;
      case 'warnCount':
        text = 'Warnings';
        background = 'var(--Indicator-Warning, #FFB900)';
        break;
      case 'errorCount':
        text = 'Errors';
        background = 'var(--Indicator-Danger, #E73550)';
        break;
      default:
        return undefined;
    }
    return (
      <Row style={{
        borderRadius: '12px', background: 'rgba(255, 255, 255, 0.20)', padding: '6px 12px', margin: 5,
      }}
      >
        <Col style={{
          borderRadius: '17px',
          border: '1px solid #FFF',
          background,
          padding: '2px 6px',
        }}
        >
          <p>{count}</p>
        </Col>
        <Col style={{
          marginLeft: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        >
          <p style={{ flex: '0 0 120px' }}>{text}</p>
        </Col>
      </Row>
    );
  };
  return (
    <div className="fp_test_exec_list_item" key={JSON.stringify(log)}>
      <Row className="fp_list_item_new">
        <Col span={6} className="right-border column-align"><p>{moment(log?.executedTime).format('MMM DD, YYYY hh:mm A')}</p></Col>
        <Col span={14} className="right-border column-align">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {Object
              .entries(log.logCount)
              .map((count: any) => renderLogCountTag(count[0], count[1]))}
          </div>
        </Col>
        <Col span={4} className="column-align">
          <Button
            style={{
              alignItems: 'center',
              border: '1px solid #844EE0',
              background: 'var(--Solid-Primary, #844EE0)',
              boxShadow: '0px 4px 4px 0px rgba(67, 0, 52, 0.05)',
              borderRadius: 8,
            }}
            onClick={() => selectedExecution(log)}
          >
            View more
          </Button>
        </Col>
      </Row>
    </div>
  );
};
export default FrontendLogsListItem;
