import { Col, Row } from 'antd';
import React from 'react';
import Loading from '../../../../components/Loading';
import TestExecutionFailedListItem from './TestExecutionFailedListItem';

interface propType {
  isLoading: boolean
  job: any
  handleEdit: Function
}

const TestExecutionFailedList = ({ isLoading = true, job, handleEdit }: propType) => (
  <div className="fp_list" style={{ marginTop: '0px' }}>
    <Row justify="start" className="fp_list_title_new">
      <Col span={6} className="column-align right-border"><p>Date Time</p></Col>
      <Col span={4} className="column-align right-border"><p>Browser</p></Col>
      <Col span={14} className="column-align"><p>Error</p></Col>
    </Row>
    {isLoading ? <Loading plain size="large" /> : job.map((failedJob: any) => (
      <TestExecutionFailedListItem
        failedJob={failedJob}
        handleEdit={handleEdit}
      />
    ))}
  </div>
);

export default TestExecutionFailedList;
