/* eslint-disable no-unused-vars */
import {
  Modal,
  Row,
  Col,
  Button,
} from 'antd';
import React, { useState } from 'react';
import renderBrowserIcon from '../../../../../components/Browsers';
import { HeroiconsDevicePhoneMobile, PepiconsPencilTablet, PhDesktop } from '../../../../../components';

interface CTRModalProps {
  open: boolean
  onClose: Function
  onRun: Function
  buttonName: string
}

export const BROWSERS = [
  'Chromium',
  'Firefox',
  'Safari',
];

const OS = {
  IOS: 'ios',
  ANDROID: 'android',
};

export const DESKTOP = {
  name: 'Desktop',
  browsers: {
    Chromium: 'Desktop_Chrome',
    Safari: 'Desktop_Safari',
    Firefox: 'Desktop_Firefox',
  },
  os: OS.IOS,
  icon: <PhDesktop />,
};

export const DEVICES = [
  {
    name: 'iPhone 13',
    fullName: 'Apple iPhone 13',
    browsers: {
      Chrome: 'iPhone_13_Mobile_Chrome',
      Firefox: 'iPhone_13_Mobile_Firefox',
      Safari: 'iPhone_13_Mobile_Safari',
    },
    os: OS.IOS,
    icon: <HeroiconsDevicePhoneMobile />,
  },
  {
    name: 'Galaxy S21',
    fullName: 'Samsung Galaxy S21',
    browsers: {
      Chrome: 'GalaxyS21_Chrome',
      Firefox: 'GalaxyS21_Firefox',
    },
    os: OS.ANDROID,
    icon: <HeroiconsDevicePhoneMobile />,
  },
  {
    name: 'Pixel 6',
    fullName: 'Google Pixel 6',
    browsers: {
      Chrome: 'Pixel6_Chrome',
      Firefox: 'Pixel6_Firefox',
    },
    os: OS.ANDROID,
    icon: <HeroiconsDevicePhoneMobile />,
  },
  {
    name: 'iPhone SE 2',
    fullName: 'Apple iPhone SE (2nd Generation)',
    browsers: {
      Chrome: 'iPhoneSE2_Chrome',
      Firefox: 'iPhoneSE2_Firefox',
      Safari: 'iPhoneSE2_Safari',
    },
    os: OS.IOS,
    icon: <HeroiconsDevicePhoneMobile />,
  },
  {
    name: 'Note 20 Ultra',
    fullName: 'Samsung Galaxy Note 20 Ultra',
    browsers: {
      Chrome: 'Note20Ultra_Chrome',
      Firefox: 'Note20Ultra_Firefox',
    },
    os: OS.ANDROID,
    icon: <HeroiconsDevicePhoneMobile />,
  },
  {
    name: 'iPad Air 4',
    fullName: 'Apple iPad Air 4th Generation',
    browsers: {
      Chrome: 'iPadAir4_Chrome',
      Firefox: 'iPadAir4_Firefox',
      Safari: 'iPadAir4_Safari',
    },
    os: OS.IOS,
    icon: <PepiconsPencilTablet />,
  },
  {
    name: 'Redmi Note 10',
    fullName: 'Xiaomi Redmi Note 10',
    browsers: {
      Chrome: 'RedmiNote10_Chrome',
      Firefox: 'RedmiNote10_Firefox',
    },
    os: OS.ANDROID,
    icon: <HeroiconsDevicePhoneMobile />,
  },
  {
    name: 'GPad 5',
    fullName: 'LG G Pad 5',
    browsers: {
      Chrome: 'GPad5_Chrome',
      Firefox: 'GPad5_Firefox',
    },
    os: OS.ANDROID,
    icon: <PepiconsPencilTablet />,
  },
  {
    name: 'iPad Mini 6',
    fullName: 'Apple iPad Mini 6th Generation',
    browsers: {
      Chrome: 'iPadMini6_Chrome',
      Firefox: 'iPadMini6_Firefox',
      Safari: 'iPadMini6_Safari',
    },
    os: OS.IOS,
    icon: <PepiconsPencilTablet />,
  },
  {
    name: 'Tab S7',
    fullName: 'Samsung Galaxy Tab S7',
    browsers: {
      Chrome: 'TabS7_Chrome',
      Firefox: 'TabS7_Firefox',
    },
    os: OS.ANDROID,
    icon: <PepiconsPencilTablet />,
  },
];

const ExecutionDeviceSelectionModal = ({
  open,
  onClose,
  onRun,
  buttonName,
}: CTRModalProps) => {
  const [selectedDevices, setSelectedDevices] = useState<string[]>([DESKTOP.name]);
  const [selectedBrowsers, setSelectedBrowsers] = useState<string[]>([]);

  const selectBrowser = (browser: string): void => {
    if (selectedBrowsers.includes(browser)) {
      const updatedList: string[] = selectedBrowsers.filter((d: string) => d !== browser);
      return setSelectedBrowsers(updatedList);
    }
    return setSelectedBrowsers((prev: string[]) => [...prev, browser]);
  };

  const onClearClose = () => {
    setSelectedDevices([DESKTOP.name]);
    setSelectedBrowsers([]);
    onClose(false);
  };

  const handleOnRun = async () => {
    const selectedEDB: string[] = [];
    selectedDevices.forEach((device: string) => {
      let sDevice: any;
      if (device === DESKTOP.name) sDevice = DESKTOP;
      else sDevice = DEVICES.find((d: any) => d.name === device);
      selectedBrowsers.forEach((b: string) => selectedEDB.push(sDevice?.browsers[b]));
    });
    onClearClose();
    await onRun(selectedEDB);
  };

  const isIosDeviceSelected: boolean = selectedDevices.some((device) => {
    if (device === DESKTOP.name) return true;
    const sDevice: any = DEVICES.find((d: any) => d.name === device);
    return sDevice?.os === OS.IOS;
  });

  return (
    <>
      <Modal
        className="max-user-modal fp-modal fp-modal-primary close-modal-icon modal-margins modal-max-top-margin"
        width={600}
        open={open}
        closable
        maskClosable={false}
        onCancel={onClearClose}
        style={{ textAlign: 'center' }}
        footer={null}
      >
        <h3 style={{ color: 'white', marginBottom: 20 }}>Select the browser you want to run your execution suite on</h3>
        <Row gutter={[16, 16]}>
          {BROWSERS.map((browser, index) => (
            <Col span={8} key={JSON.stringify({ browser, index })}>
              <Button
                onClick={() => selectBrowser(browser)}
                style={{ background: selectedBrowsers.includes(browser) ? 'linear-gradient(90deg, rgba(132, 78, 224, 0.50) 0%, rgba(46, 198, 178, 0.50) 100%)' : 'rgba(255, 255, 255, 0.2)' }}
                className="fp_btn fp_btn-browser-select-button"
                disabled={browser === BROWSERS[2] && !isIosDeviceSelected}
              >
                <div style={{ width: 23, marginBottom: 10 }}>
                  {renderBrowserIcon(browser)}
                </div>
                {browser}
              </Button>
            </Col>
          ))}
        </Row>
        <div style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 140,
        }}
        >
          <Button onClick={handleOnRun} type="primary" className="fp_btn fp_btn-tertiary" style={{ width: 100, marginLeft: '0px' }} disabled={selectedDevices.length < 1 || selectedBrowsers.length < 1}>{buttonName}</Button>
        </div>
      </Modal>
    </>
  );
};

export default ExecutionDeviceSelectionModal;
