import React, { useState } from 'react';
import {
  Button,
  Col, Dropdown, Menu, Row,
} from 'antd';
import moment from 'moment';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { MoreOutlined } from '@ant-design/icons';
import {
  ChromiumIcon, EditIcon, FirefoxIcon, SafariIcon,
} from '../../../../components';
import { BROWSER_TYPE } from '../../../../types/common';
import { ENABLE_MONITOR_MODE } from '../../../../configs/app-config';

// eslint-disable-next-line no-control-regex
const pattern = /\u001b\[(\d+)(;\d+)*m/g;

const TestExecutionFailedListItem = (
  { failedJob, handleEdit }: { failedJob: any, handleEdit: Function },
) => {
  const [viewMore, setViewMore] = useState<boolean>(false);

  const menuItems: any[] = [
    {
      key: '1',
      label: (
        <Button
          className="fp_btn fp_btn-link"
          onClick={() => handleEdit(failedJob)}
          style={{ width: '100%', textAlign: 'left' }}
          icon={<EditIcon />}
        >
          Edit
        </Button>
      ),
    },
  ];

  const getBrowserIcon = (browser: string) => {
    switch (browser) {
      case BROWSER_TYPE.CHROMIUM:
        return <ChromiumIcon />;
      case BROWSER_TYPE.SAFARI:
        return <SafariIcon />;
      case BROWSER_TYPE.FIREFOX:
        return <FirefoxIcon />;
      default:
        return <></>;
    }
  };

  return (
    <div className="fp_test_exec_list_item" key={JSON.stringify(failedJob)}>
      <Row className="fp_list_item_new">
        <Col span={6} className="right-border column-align"><p>{moment(failedJob.executedTime).format('MMM DD, YYYY hh:mm A')}</p></Col>
        <Col span={4} className="right-border column-align">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ width: '25px', height: '25px', marginRight: '10px' }}>
              {getBrowserIcon(failedJob?.browser?.replace('Desktop_', ''))}
            </div>
            <p>{failedJob?.browser?.replace('Desktop_', '')}</p>
          </div>
        </Col>
        <Col span={8} className="column-align">
          <p>{(failedJob?.error?.message || failedJob?.error?.stack).replace(pattern, '')}</p>
        </Col>
        <Col span={4} className="column-align">
          <Button
            type="primary"
            className="fp_btn fp_btn-view"
            style={{ fontSize: '16px' }}
            onClick={() => setViewMore(!viewMore)}
          >
            {viewMore ? 'View Less' : 'View More'}
          </Button>
        </Col>
        {!ENABLE_MONITOR_MODE && (
          <Col span={2} className="column-align">
            <Row>
              <Dropdown overlay={(
                <Menu
                  className="fp-drop-down-menu"
                  items={menuItems}
                />
            )}
              >
                <Button className="fp-drop-down-button" style={{ padding: 0 }}>
                  <MoreOutlined
                    className="fp_list_item_more_icon"
                  />
                </Button>
              </Dropdown>
            </Row>
          </Col>
        )}
      </Row>
      {viewMore && (
      <Row>
        <CodeEditor
          value={failedJob?.error?.explainedError}
          language="json"
          readOnly
          className="fp_logview_editor fp_logview_editor-full-width"
          padding={15}
          disabled
        />
      </Row>
      )}
    </div>
  );
};
export default TestExecutionFailedListItem;
