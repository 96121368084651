export const CONFIG = {
  stage: 'dev',
  baseApiUrl: 'https://dev-api.footprint.systems/dev-',
};

export const AWS_CONFIG = {
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_3OojPreoj',
    userPoolWebClientId: '46gcnglaimkoum0a8j32fg440j',
    oauth: {
      domain: 'dev-loadtester-io.auth.us-west-2.amazoncognito.com',
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: 'https://dev.footprint.systems',
      redirectSignOut: 'https://dev.footprint.systems/login',
      responseType: 'code',
    },
  },
};

export const INTEGRATION_TOOL = {
  bitbucket: 'docker://dilshansmbapps/footprint-pipeline-pipe:latest',
};

export const INTEGRATION_PUBLIC_TOKEN_DECRYPT_KEY = 'mh&oe3WL^PKN64xrmkdFt&3FgsLqpm#iSGA@r#54U523j2F4u2riP2GiEbrdGRMumZhyiXDvJzU2ADoh9wuQGDkrvAn4Kdvp2y%uAxPKm#UCc3jQdDrcAhKLVir!Mc$9';

export const STRIPE_KEY = 'pk_test_51MMTJVIfJgoairGjIIgUMvpDOmjO9BdCO9FuEweVRb2RPWkEuAaRIz4v3RqWhKh1hMEunKEoQ4MLfged502B69uG00wRq33oBy';

export const RECAPTCHA_SITE_KEY = '6LeRcD4lAAAAANgZsgjmLz3soL3ymQWyePQqXZuc';

export const AMPLITUDE_KEY = '';
export const JUNEIFY_KEY = undefined;

export const CODEGEN_SERVER_URL = 'https://live-recorder.dev.vnc.footprint.systems';

export const ENABLE_MONITOR_MODE = true;
