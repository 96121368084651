import { notification } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { API } from 'aws-amplify';
import axios from 'axios';
import { setBilledPlans } from '../../redux/billedPlanSlice';
import { AppDispatch } from '../../redux/store';
import { TestCaseGenerateInput } from '../../redux/testCaseSlice';
import { contactUsParams, ServiceType, SupportInfoType } from '../../types/common';
import { APIS } from '../../utils/apiUtils';
import { notifyError, notifyInProgress, notifySuccess } from '../../utils/notification';
import { ScriptType } from './components/testCaseCreatedResult/modals';

export type feedbackParams = {
  accountId: string,
  description: string,
  feedbackType: string,
  requirementId: string,
  entityId: string,
  counts: object,
};

export const ACTION = {
  EDIT: 'edit',
  DELETE: 'delete',
};

type ActionType = typeof ACTION[keyof typeof ACTION];

export const getPaymentPlan = async (dispatch: AppDispatch) => {
  try {
    const data = await API.get(APIS.TESTCASE_CREATING_API, '/payment-plans', {});
    dispatch(setBilledPlans(data));
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const genarateTestcase = async (testCase: TestCaseGenerateInput) => {
  try {
    const data = await API.post(APIS.TESTCASE_CREATING_API, '/create', { body: testCase });
    return data;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const addPaymentMethod = async (accountId: string, body: object) => {
  try {
    await API.post(APIS.BILLING_API, '/add-payment-method', { queryStringParameters: { accountId }, body });
    return true;
  } catch (error) {
    notifyError("Something wen't wrong unable to add new card.");
    return false;
  }
};
export const setUpPaymentPlan = async (
  accountId: string, paymentPlanId: string, paymentType: string,
) => {
  try {
    await API.get(APIS.BILLING_API, '/subscribe', { queryStringParameters: { accountId, paymentPlanId, paymentType } });
    return true;
  } catch (error) {
    notifyError("Something wen't wrong unable to subscribe to plan.");
    return false;
  }
};
export const getCardList = async (customerId: string) => {
  try {
    const response = await API.get(APIS.BILLING_API, '/get-card-list', { queryStringParameters: { customerId } });
    return response.cardList.data;
  } catch (error) {
    notifyError("Something wen't wrong unable to fetch available card details.");
    return [];
  }
};

export const GetTestCaseResultView = async (requirementId: string, moduleId: string) => {
  try {
    return await API.get(APIS.TESTCASE_CREATING_API, '/testcases', { queryStringParameters: { requirementId, moduleId } });
  } catch (error: any) {
    throw new Error('No result found');
  }
};

export const addServiceRecord = async (
  accountId: string, paymentType: string, paymentPlanId: string, testCaseCount: number,
) => {
  notification.destroy();
  try {
    return await API.get(APIS.BILLING_API, '/add-service-record', {
      queryStringParameters: {
        accountId, paymentType, paymentPlanId, testCaseCount,
      },
    });
  } catch (error: any) {
    notifyError(error.response.data.message, 'add-service-record-error', 10000);
    throw new Error('No result found');
  }
};

export const addFeedback = async (feedbackDataset: feedbackParams) => {
  notifyInProgress('Feedback adding in Progress', 'feedvack_create');
  try {
    await API.post(APIS.FEEDBACK_API, '/create', { body: feedbackDataset });
    notification.destroy();
  } catch (error: any) {
    notification.destroy();
    throw new Error('Feedback Adding went wrong');
  }
};

export const generateSupportRequest = async (supportInfo: SupportInfoType) => {
  try {
    const data = await API.post(APIS.TESTCASE_CREATING_API, '/support', { body: supportInfo });
    return data;
  } catch (error: unknown) {
    if (error instanceof Error) {
      return error.message ? error.message : 'Error occured';
    }
    return 'Error occured';
  }
};

export const getSignedUrl = async (key: string, contentType = '', method = 'getObject') => {
  const { body } = await API.get(APIS.TESTCASE_CREATING_API, '/get-signed-url', { queryStringParameters: { key, contentType, method } });
  return body.url;
};

export async function uploadFile(url: string, file: any | RcFile | File, contentType: string) {
  await axios.put(url,
    file,
    {
      headers: {
        'Content-Type': contentType, // Set the content type of your file
      },
    });
}

export const generateScript = async (queryStringParameters: any) => {
  try {
    notifyInProgress('Starting script generator...');
    await API.get(APIS.TESTCASE_CREATING_API, '/script/generate', { queryStringParameters });
    notification.destroy();
    notifySuccess('Generating your script!');
  } catch (error: any) {
    notification.destroy();
    notifyError('Faild to generate script', 'Faild_to_generate_script');
  }
};

export const getGeneratorStatus = async (
  requirementId: string, testcaseId: string, type: string,
) => {
  try {
    const results = await API.get(APIS.TESTCASE_CREATING_API, '/script/get-status', {
      queryStringParameters: {
        requirementId, testcaseId, type,
      },
    });
    notification.destroy();
    return results;
  } catch (error: any) {
    notification.destroy();
    notifyError('Faild to get generator status', 'Faild_to_generator_status');
    return null;
  }
};

export const addContactUs = async (contactUsData: contactUsParams) => {
  try {
    notifyInProgress('Sending contact details...', 'contact_us_inprogress');
    await API.post(APIS.CONTACT_US_API, '/contact', { body: contactUsData });
    notification.destroy();
    notifySuccess('Contact details send successfully', 'contact_us_success');
  } catch (error: any) {
    notification.destroy();
    notifyError('Contact request faild', 'contact_us_faild');
  }
};

export const topUpUserAccount = async (accountId: string, serviceId: string, amount: number) => {
  try {
    return await API.get(APIS.BILLING_API, '/top-up', { queryStringParameters: { accountId, serviceId, amount } });
  } catch (error: any) {
    notification.destroy();
    notifyError('Contact request faild', 'contact_us_faild');
    return { client_secret: undefined };
  }
};

export const customerConfirmCardPayment = async (stripe: any, clientSecret: string) => {
  try {
    const response = await stripe.confirmCardPayment(clientSecret);
    notification.destroy();
    notifySuccess('Your top-up card payment confirmed', 'your_top_up_card_payment_confirmed');
    return response;
  } catch (error) {
    notification.destroy();
    notifyError('Unable to confirm the card payment', 'unable_to_confirm_the_card_payment');
    return error;
  }
};

export const getModulesByProjectId = async (projectId: string) => {
  try {
    return await API.get(APIS.TESTCASE_CREATING_API, '/test-modules', { queryStringParameters: { projectId } });
  } catch (error: any) {
    notification.destroy();
    notifyError('Failed to fetch modules data', 'failed_to_fetch_modules_data');
    throw new Error('No result found');
  }
};

export const changeServiceSubscription = async (
  accountId: string, serviceId: string, newPlan: string,
) => {
  try {
    notifyInProgress('Changing Service Subscription...', 'changing_service_subscription');
    await API.get(APIS.BILLING_API, '/change-service-subscription', { queryStringParameters: { accountId, serviceId, newPlan } });
    notification.destroy();
    notifySuccess('Service Subscription Changed Successfully!', 'service_subscription_changed_successfully');
  } catch (error: any) {
    notification.destroy();
    notifyError('Failed To Change Service Subscription', 'failed_to_change_service_subscription');
  }
};

export const triggerMASGenerator = async (payload: {
  testcases?: string[],
  accountId: string,
  projectId: string,
  moduleId: string,
  requirementId: string,
  masterScriptName: string,
  masterScript?: string,
}): Promise<string | void> => {
  try {
    notifyInProgress('Starting master automation script Generator', 'generating_master_automation_script');
    const res: string = await API.post(APIS.TESTCASE_CREATING_API, '/testcase/master-automation-script', { body: payload });
    notification.destroy();
    return res;
  } catch (error: any) {
    notification.destroy();
    notifyError('Failed to trigger master automation script generator', 'failed_to_trigger_master_automation_script_generator');
    return undefined;
  }
};

export const getMasterScript = async (payload: { key: string }): Promise<boolean | string> => {
  try {
    const { script } = await API.post(APIS.TESTCASE_CREATING_API, '/testcase/master-automation-script/get', { body: payload });
    return script;
  } catch (error: any) {
    notification.destroy();
    notifyError('Failed to fetch selected master automation script', 'failed_to_fetch_selected_master_automation_script');
    return false;
  }
};

export const editDeleteMAS = async (payload: {
  key: string, type: ActionType, updatedContent?: string
}): Promise<boolean> => {
  try {
    return await API.post(APIS.TESTCASE_CREATING_API, '/testcase/master-automation-script/edit-delete', { body: payload });
  } catch (error) {
    notification.destroy();
    notifyError('Failed to fetch selected master automation script', 'failed_to_fetch_selected_master_automation_script');
    return false;
  }
};

export type RunTestCasePayload = {
  accountId: string,
  projectId: string,
  testcaseId?: string | undefined,
  userId: string,
  isDryRun?: boolean,
  service: ServiceType,
  isMasterScript: boolean,
  requirementId: string,
  automationScript?: string | undefined,
  moduleId?: string | undefined,
  masterScript?: ScriptType | undefined,
  selectedEDB: string[]
};

export const runTestCase = async (params: RunTestCasePayload): Promise<boolean> => {
  try {
    await API.post(APIS.EXECUTION_API, '/place-execution', { body: params });
    return true;
  } catch (error: any) {
    console.log(error.message);
    notification.destroy();
    notifyError('Failed to run test case');
    return false;
  }
};

export const getExecution = async (
  testcaseId: string,
  executionId: string,
  monitorId?: string | undefined,
): Promise<any> => {
  try {
    return await API.get(APIS.TESTCASE_CREATING_API, '/testcase/automation-script-execution/get-execution', {
      queryStringParameters: {
        testcaseId, executionId, monitorId,
      },
    });
  } catch (error) {
    notification.destroy();
    return {};
  }
};

export const cancelExecution = async (params: any): Promise<any> => {
  try {
    notifyInProgress('Canceling execution');
    return await API.get(APIS.TESTCASE_CREATING_API, '/testcase/automation-script-execution/cancel-execution', { queryStringParameters: params });
  } catch (error) {
    notification.destroy();
    return {};
  }
};

export const addTestCaseRating = async (params: any): Promise<any> => {
  try {
    notifyInProgress('Saving your feedback');
    return await API.get(APIS.TESTCASE_CREATING_API, '/testcase/rate-test-case', { queryStringParameters: params });
  } catch (error) {
    notification.destroy();
    return {};
  }
};

export const createMonitorJob = (payload: any) => API.post(APIS.MONITOR_API, '/create-monitor', { body: payload });
