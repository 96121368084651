/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Row, Button, Col,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import ExecutionTimeChartView from './ExecutionTimeChartView';
import AverageMemoryUsageChartView from './AverageMemoryUsageChartView';
import PageLoadPerformanceChartView from './PageLoadPerformanceChartView';
import { getAnalytics } from './action';
import { useAppSelector } from '../../redux/hooks';
import TestExecutionFailedListModal from './modals/testExecutionFailedListModal/TestExecutionFailedListModal';
// import APIEndPointsPerformanceChartView from './APIEndPointsPerformanceChartView';
import MemoryUsageStatsChart from './MemoryUsageStatsChart';
import FrontendLogsModal from './modals/frontendLogsModal/FrontendLogsModal';
import { initApi } from '../../utils/apiUtils';
import Loading from '../../components/Loading';

const AnalyticsView = () => {
  const { monitorId, shareId } = useParams<any>();
  const accountId = useAppSelector(({ userData }: any) => userData?.selectedAccount?.accountId);
  const history = useHistory();
  const [isTEFLModal, setTEFLModal] = useState<boolean>(false);
  const [viewFrontendLogsModal, setViewFrontendLogsModal] = useState<boolean>(false);

  const [analyticsData, setAnalyticsData] = useState<any>();

  const fetchData = async () => {
    const data = await getAnalytics(shareId, accountId, monitorId);
    setAnalyticsData(data);
  };

  useMemo(() => {
    if (monitorId || shareId) {
      if (shareId) {
        const token: null | string = window.localStorage.getItem(shareId);
        if (token) {
          initApi(token);
        }
      }
      fetchData();
    }
  }, [monitorId, shareId]);

  useEffect(() => () => {
    setAnalyticsData(undefined);
  }, []);

  return (
    <div className="fp_pg_base fp_test_exec_suit">
      <Row>
        <Button
          data-testid="fp_previous_btn"
          className="back_btn fp_btn-primary fp_btn"
          onClick={() => history.goBack()}
        >
          Back
        </Button>
      </Row>
      <Row style={{ marginTop: '-50px' }}>
        <p className="fp_heading-2 fp_heading-2-middle">Analytics</p>
      </Row>
      <Row>
        <p className="fp_medium-text-2 fp_medium-text-2-middle">{analyticsData?.job?.name}</p>
      </Row>
      <Row>
        <Col offset={21}>
          <Button
            style={{
              alignItems: 'center',
              background: 'linear-gradient(90deg, rgba(132, 78, 224, 0.50) 0%, rgba(46, 198, 178, 0.50) 100%)',
              border: '1.5px solid var(--Gradient-Stroke, #2EC6B2)',
              borderRadius: 12,
            }}
            disabled={!analyticsData?.report}
            onClick={() => setViewFrontendLogsModal(true)}
          >
            Frontend logs
          </Button>
        </Col>
      </Row>
      <Row style={{ marginTop: '-35px' }}>
        <Col span={13} className="fp_list" style={{ marginLeft: '1%' }}>
          <Row justify="start" className="fp_list_item_new" style={{ padding: '0px 0px' }}>
            <Col span={7} className="right-border column-align">
              <div style={{ display: 'flex' }} className="column-align">
                <p style={{ margin: '0 10px 0 0' }}>Repeat : </p>
                <p className="fp_medium-text-2 fp_medium-text-2-secondary">{analyticsData?.job?.repeatFrequency}</p>
              </div>
            </Col>
            <Col span={17} className="column-align">
              <div style={{ display: 'flex' }} className="column-align">
                <p style={{ margin: '0 10px 0 0' }}>Duration : </p>
                {analyticsData?.job && (
                  <p className="fp_medium-text-2 fp_medium-text-2-secondary">
                    {`${moment(analyticsData?.job?.startDate).format('MMM DD, YYYY hh:mm A')} - ${moment(analyticsData?.job?.endDate).format('MMM DD, YYYY hh:mm A')}`}
                  </p>
                )}
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={10} className="fp_list" style={{ marginLeft: '2%' }}>
          <Row justify="start" className="fp_list_item_new" style={{ padding: '5px 0px' }}>
            <Col span={7} className="column-align"><p>Test Executions</p></Col>
            <Col span={7} className="column-align">
              <div className="execution_result-div">
                <p className="right-border" style={{ width: '80px', alignItems: 'center' }}>Passed</p>
                <p
                  style={{
                    width: '80px', alignItems: 'center', color: 'rgba(34, 169, 134, 1)',
                  }}
                  className="fp_medium-text-2 fp_medium-text-2-ternary"
                >
                  {analyticsData?.report?.testExecutions?.passed || 0}
                </p>
              </div>
            </Col>
            <Col span={7} className="column-align">
              <div style={{ marginLeft: '35px' }} className="execution_result-div">
                <p className="right-border" style={{ width: '80px', alignItems: 'center' }}>Failed</p>
                <Button
                  style={{
                    width: '80px', alignItems: 'center', color: 'rgba(231, 53, 80, 1)', background: 'none', border: 'none', paddingTop: '0px',
                  }}
                  className="fp_medium-text-2 fp_medium-text-2-ternary"
                  onClick={() => setTEFLModal(true)}
                  disabled={parseInt(analyticsData?.report?.testExecutions?.failed || '0', 10) <= 0}
                >
                  {analyticsData?.report?.testExecutions?.failed || 0}
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {analyticsData ? (
        <>
          <ExecutionTimeChartView
            executionTime={analyticsData?.report?.executionTime || []}
            selectedEDB={analyticsData?.job?.selectedEDB || []}
          />
          {/* <MemoryUsageStatsChart
            memoryUsageStats={analyticsData?.report?.memoryUsageStats}
          />
          <AverageMemoryUsageChartView
            averageMemoryUsage={analyticsData?.report?.averageMemoryUsage || []}
            selectedEDB={analyticsData?.job?.selectedEDB || []}
          />
          <PageLoadPerformanceChartView
            pageLoadPerformance={analyticsData?.report?.pageLoadPerformance || []}
            selectedEDB={analyticsData?.job?.selectedEDB || []}
          /> */}
          {/* <APIEndPointsPerformanceChartView
        APIEndpointPerformance={analyticsData?.report?.APIEndpointPerformance || []}
      /> */}

          <TestExecutionFailedListModal
            open={isTEFLModal}
            onClose={setTEFLModal}
          />
          <FrontendLogsModal
            open={viewFrontendLogsModal}
            selectedEDB={analyticsData?.job?.selectedEDB || []}
            onClose={setViewFrontendLogsModal}
          />
        </>
      ) : <Loading plain size="large" />}
    </div>
  );
};
export default AnalyticsView;
