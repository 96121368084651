import React, { useEffect, useState } from 'react';
import {
  Col, Row, Button,
  Pagination,
  PaginationProps,
  Input,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { RedoOutlined, SearchOutlined } from '@ant-design/icons';
import {
  AnalyticsIcon,
} from '../../components';
import MonitoringSchedule from './MonitoringItemSchedule';
import ResultLogs from './MonitoringItemResultLogList';
import { useAppSelector } from '../../redux/hooks';
import { authenticateGuest, getMonitorJob } from './action';
import { ROUTES } from '../../constants';
import OTPAuthenticationModal from './modals/OTPAuthenticationModal';
import { initApi } from '../../utils/apiUtils';
import { ENABLE_MONITOR_MODE } from '../../configs/app-config';

const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return <a className="nav-buttons">Previous</a>;
  }
  if (type === 'next') {
    return <a className="nav-buttons">Next</a>;
  }
  return originalElement;
};

const MonitoringJobListViewItemView = () => {
  const [job, setJob] = useState<any>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isResultLogsLoading, setResultLogsLoading] = useState<boolean>(true);
  const [testRuns, setTestRuns] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [openOTPAuthenticationModal, setOpenOTPAuthenticationModal] = useState<boolean>(false);
  const [isSearching, setSearching] = useState<boolean>(false);
  const [isSearchLoad, setSearchLoad] = useState<boolean>(false);

  const { monitorId, shareId } = useParams<any>();
  const accountId = useAppSelector(({ userData }: any) => userData?.selectedAccount?.accountId);
  const history = useHistory();

  const fetchData = async (lastEvaluatedKey?: any, keyword?: string | undefined) => {
    if (keyword) {
      setSearchLoad(true);
    } else {
      setLoading(true);
    }
    setResultLogsLoading(true);
    const data = await getMonitorJob(
      shareId, accountId, monitorId, JSON.stringify(lastEvaluatedKey), keyword,
    );
    setJob(data);
    setTestRuns(data.testRuns);
    setLoading(false);
    setSearchLoad(false);
    setResultLogsLoading(false);
  };

  const setPages = (segmentNumber: number) => {
    const itemsPerPage = 10;
    const startIndex = (segmentNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setTestRuns(job.testRuns.slice(startIndex, endIndex));
    setResultLogsLoading(false);
  };

  const handlePagination = async (newPage: number) => {
    setResultLogsLoading(true);
    if ((newPage * 10) > job.testRuns.length) {
      const data = await getMonitorJob(
        shareId, accountId, monitorId, JSON.stringify(job.lastEvaluatedKey),
      );
      setJob((prev: any) => ({ ...prev, ...data, testRuns: [...prev.testRuns, ...data.testRuns] }));
    }
    setPage(newPage);
  };

  const authenticateGuestUser = async (value: string) => {
    try {
      setOpenOTPAuthenticationModal(false);
      const { token } = await authenticateGuest({ shareId, otp: value });
      initApi(token);
      window.localStorage.setItem(shareId, token);
      await fetchData();
    } catch (err: any) {
      window.localStorage.removeItem(shareId);
      setOpenOTPAuthenticationModal(true);
    }
  };

  let timeout: any = null;
  const handleSearch = (keyword: string) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setSearching(keyword.length > 0);
      fetchData(undefined, keyword);
    }, 500);
  };

  useEffect(() => {
    if (page && job?.testRuns) {
      setPages(page);
    }
  }, [page, job?.testRuns]);

  useEffect(() => {
    if (shareId) {
      const token: null | string = window.localStorage.getItem(shareId);
      if (!token) {
        setOpenOTPAuthenticationModal(true);
      } else {
        initApi(token);
        fetchData();
      }
    } else if ((!shareId && monitorId && accountId)) {
      fetchData();
    }
  }, [monitorId, shareId, accountId]);

  useEffect(() => () => {
    setJob(undefined);
    setLoading(true);
    setResultLogsLoading(true);
    setTestRuns([]);
    setPage(1);
    setSearching(false);
    setSearchLoad(false);
  }, []);

  const handleAnalyticsButton = () => {
    history.push(shareId ? `${ROUTES.SHARE_ANALYTICS}/${shareId}` : `${ROUTES.ANALYTICS}/${monitorId}`);
  };

  return (
    <div className="fp_pg_base fp_test_exec_suit">
      <Row>
        <Col span={ENABLE_MONITOR_MODE ? 19 : 20}>
          {!shareId && (
          <Button
            data-testid="fp_previous_btn"
            className="back_btn fp_btn-primary fp_btn"
            onClick={() => history.goBack()}
          >
            Back
          </Button>
          )}
        </Col>
      </Row>
      <Row style={{ marginTop: '-50px', textAlign: 'center' }} justify="center">
        <Col span={18}>
          <p className="fp_heading-2 fp_heading-2-middle">{job?.name}</p>
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Button className="fp_btn fp_btn-refresh-btn" type="primary" icon={<RedoOutlined />} onClick={() => fetchData()}>
            Refresh
          </Button>
        </Col>
        <Col style={{ marginLeft: 20 }}>
          <Button className="fp_btn fp_btn-analytics" type="primary" icon={<AnalyticsIcon />} onClick={handleAnalyticsButton}>
            Analytics
          </Button>
        </Col>
      </Row>
      <Row>
        <p className="fp_heading-2 fp_heading-2-left">Monitoring Schedule</p>
      </Row>
      <MonitoringSchedule isLoading={isLoading} job={job} />
      <Row>
        <p className="fp_heading-2 fp_heading-2-left">Result logs</p>
      </Row>
      <Row justify="end">
        <Col span={6}>
          <Input
            prefix={<SearchOutlined />}
            className="fp_monitor_job_results_log_search"
            placeholder="Search"
            onChange={(text) => handleSearch(text.target.value)}
            data-testid="fp_monitor_job_results_log_search"
            disabled={isLoading}
          />
        </Col>
      </Row>
      <ResultLogs
        isLoading={isResultLogsLoading || isSearchLoad}
        job={job}
        testRuns={testRuns}
        isShared={!!shareId}
        isSearching={isSearching}
      />
      {!isSearching && (
      <Row>
        <Col span={24} style={{ textAlign: 'right' }} className="pagination-container">
          {job && !isResultLogsLoading && (
          <Pagination
            defaultCurrent={1}
            current={page}
            total={job.lastEvaluatedKey ? job.testRuns.length + 10 : job.testRuns.length}
            onChange={handlePagination}
            itemRender={itemRender}
          />
          )}
        </Col>
      </Row>
      )}
      <OTPAuthenticationModal
        open={openOTPAuthenticationModal}
        onOkay={authenticateGuestUser}
        onCancel={setOpenOTPAuthenticationModal}
      />
    </div>
  );
};
export default MonitoringJobListViewItemView;
