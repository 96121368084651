import React, { useState } from 'react';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { CopyOutlined } from '@ant-design/icons';
import { Tooltip, Button } from 'antd';

type TextEditorProps = {
  value: string;
  language: string;
  readOnly?: boolean;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  style?: React.CSSProperties;
  padding?: number;
  disabled?: boolean;
  allowCopyOption: boolean;
};

const TextEditor = ({
  disabled,
  value,
  language,
  readOnly,
  placeholder,
  onChange,
  style,
  padding = 15,
  allowCopyOption = false,
}: TextEditorProps) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1500);
  };

  return (
    <div style={{ position: 'relative' }}>
      <CodeEditor
        value={value}
        language={language}
        readOnly={readOnly}
        style={style}
        className="fp_logview_editor"
        placeholder={placeholder}
        onChange={onChange}
        padding={padding}
        disabled={disabled}
      />
      {allowCopyOption && (
      <Tooltip title={copySuccess ? 'Copied!' : 'Copy to Clipboard'} placement="topRight">
        <Button
          type="text"
          icon={<CopyOutlined />}
          onClick={handleCopy}
          style={{ position: 'absolute', top: 8, right: 8 }}
        />
      </Tooltip>
      )}
    </div>
  );
};

export default TextEditor;
