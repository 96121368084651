import {
  Modal,
  Row,
  Form,
  Col,
  DatePicker,
  TimePicker,
  Button,
  Input,
  Select,
} from 'antd';
import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import {
  DateIcon,
  TimeIcon,
  PhTag,
  Icons,
} from '../../../../../components';

interface ExecutionMonitoringModalProps {
  open: boolean
  onClose: Function
  form: any
}

const ExecutionMonitoringModal = ({
  open,
  onClose,
  form,
}: ExecutionMonitoringModalProps) => {
  const handleNextButton = async () => {
    await form.validateFields();
    onClose(true);
  };

  return (
    <>
      <Modal
        className="max-user-modal fp-modal fp-modal-primary modal-margins"
        width={600}
        open={open}
        title="Synthetic monitoring"
        closable
        maskClosable={false}
        onCancel={() => onClose()}
        footer={[]}
      >
        <Form
          layout="vertical"
          wrapperCol={{ span: 12 }}
          className="required-icon"
          form={form}
        >
          <Row>
            <Col className="form-col">
              <DateIcon style={{ textAlign: 'center', marginTop: '40px', marginRight: '20px' }} />
              <Form.Item name="fromdate" label="From" rules={[{ required: true, message: 'Date is required!' }]}>
                <DatePicker suffixIcon={<DownOutlined />} format="dddd, MMMM D" className="fb_picker_input" />
              </Form.Item>
            </Col>
            <Col className="form-col">
              <Icons style={{ textAlign: 'center', marginTop: '40px', marginRight: '20px' }} />
              <Form.Item name="todate" label="To" rules={[{ required: true, message: 'Date is required!' }]}>
                <DatePicker suffixIcon={<DownOutlined />} format="dddd, MMMM D" className="fb_picker_input" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col className="form-col">
              <TimeIcon style={{ textAlign: 'center', marginTop: '40px', marginRight: '20px' }} />
              <Form.Item name="starttime" label="Start Time" rules={[{ required: true, message: 'Start time is required!' }]}>
                <TimePicker suffixIcon={<DownOutlined />} format="h:mm a" className="fb_picker_input" placeholder="Select a start time" />
              </Form.Item>
            </Col>
            <Col className="form-col">
              <TimeIcon style={{ textAlign: 'center', marginTop: '40px', marginRight: '20px' }} />
              <Form.Item name="endTime" label="End Time" rules={[{ required: true, message: 'End time is required!' }]}>
                <TimePicker suffixIcon={<DownOutlined />} format="h:mm a" className="fb_picker_input" placeholder="Select a end time" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <PhTag style={{ textAlign: 'center', marginTop: '40px', marginRight: '20px' }} />
            <Form.Item name="frequency" label="Repeat" rules={[{ required: true }]}>
              <Select
                className="fb_select-max-width selection-menu"
                style={{ width: 490 }}
                options={
                    [
                      {
                        value: '15 minutes',
                        label: 'Every 15 minutes',
                      },
                      {
                        value: '30 minutes',
                        label: 'Every 30 minutes',
                      },
                      {
                        value: '60 minutes',
                        label: 'Every 60 minutes',
                      },
                      {
                        value: '24 hours',
                        label: 'Every 24 hours',
                      },
                    ]
                      }
                placeholder="Select a frequency"
              />
            </Form.Item>
          </Row>
          <Row>
            <PhTag style={{ textAlign: 'center', marginTop: '40px', marginRight: '20px' }} />
            <Form.Item name="email" label="Fail alert notifier" rules={[{ type: 'email', required: true, message: 'Enter correct email' }]}>
              <Input style={{ width: 490 }} placeholder="Enter an email to notify execution fails" className="fb_picker_input" />
            </Form.Item>
          </Row>
          <Row>
            <Button className="fp_btn fp_btn-tertiary" onClick={handleNextButton}>Next</Button>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ExecutionMonitoringModal;
