import React, { useEffect, useMemo, useState } from 'react';
import {
  Button, Col, Collapse, Modal, Row, Typography, notification,
} from 'antd';
import { CaretRightOutlined, PauseCircleOutlined } from '@ant-design/icons';
import ConfirmationModal from './ConfirmationModal';
import StatusView from '../../../../testcases/result/components/StatusView';
import { ScriptType } from '.';
import { notifyInProgress, notifySuccess } from '../../../../../utils/notification';
import { extractTestBlock, mergeTestBlocks, toCamelCase } from '../../../../../utils/commonUtils';
import Loading from '../../../../../components/Loading';
import EditorIDE from '../components/EditorIDE';
import IDETabMenu from '../components/IDETabMenu';
import { EXECUTION_RESULT } from '../../../../../constants';
import {
  CTR_IMPORT_TYPE, ENTRY_TYPE, SCRIPT_STATUS, SCRIPT_TYPE,
} from '../../../../../types/common';
import {
  editDeleteMAS, ACTION, getExecution,
} from '../../../action';
import CTRModal from '../../../../projects/project/modals/CTRModal';
import { CommonResource } from '../../../../../redux/projectSlice';
import PopUpNotification from '../../../../../components/PopUpNotification';
import ExecutionDeviceSelectionModal from './ExecutionDeviceSelectionModal';
import { PasteIcon, SmallLogo } from '../../../../../components';
import { addTestCase } from '../../../../testcases/action';
import ExtensionInstructionContent from './ExtensionInstructionContent';

const { Panel } = Collapse;

interface proType {
  open: boolean
  setOpen: Function
  saveTestCase: Function
  form: any
  summeryData: any
  testcase: any
  setSelectedTestCase: Function
  selectedScript: any
  setSelectedScript: Function
  runAutomationScript: Function
  selectedMasterScript?: ScriptType | undefined
  setSelectedMasterScript: Function
  reGenerateTCScript: Function
  cancelExecutionScript: Function
  projectId: string
  projectName: string
  triggerAutoRefresh: Function
  setNewTestCase: Function
}

const CONFIRMATION_FOR = {
  TESTCASE: 'testcase',
  RE_GENERATE: 're-generate',
};

const { Title } = Typography;

const IDEModal = ({
  open, setOpen, saveTestCase, form, summeryData, testcase, runAutomationScript,
  selectedScript = SCRIPT_TYPE.AUTOMATION_SCRIPT, setSelectedScript,
  selectedMasterScript, setSelectedMasterScript, setSelectedTestCase, reGenerateTCScript,
  cancelExecutionScript, projectId, projectName, triggerAutoRefresh, setNewTestCase,
}: proType) => {
  const { requirement, testcases = [] } = summeryData;

  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
  const [executionStatus, setExecutionStatus] = useState<string | undefined>();
  const [executionData, setExecutionData] = useState<any | undefined>();
  const [addedPreRequisiteScripts, setAddedPreRequisiteScripts] = useState<any[]>([]);
  const [confirmationFor, setConfirmationFor] = useState<string>(CONFIRMATION_FOR.TESTCASE);
  const [script, setScript] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isCTRModalOpen, setCTROpenModal] = useState<boolean>(false);
  const [showCTRNotification, setShowCTRNotification] = useState<boolean>(false);
  const [openEDSmodel, setEDSModel] = useState<boolean>(false);
  const [isScriptRunning, setScriptRunning] = useState<boolean>(false);

  const triggerConfirmationModal = (cFor: string) => {
    setOpen(false);
    setConfirmationModalOpen(true);
    setConfirmationFor(cFor);
  };

  const handleFullCleanUp = () => {
    form.resetFields();
    setSelectedTestCase(undefined);
    setExecutionData(undefined);
    setScript('');
    setSelectedScript(undefined);
    setExecutionStatus(undefined);
    setSelectedMasterScript(undefined);
    setShowCTRNotification(false);
    setCTROpenModal(false);
    setEDSModel(false);
    setAddedPreRequisiteScripts([]);
    setScriptRunning(false);
    if (!isLoading) setLoading(true);
    setConfirmationFor(CONFIRMATION_FOR.TESTCASE);
  };

  const handleCancel = ({ closeModal = true }: { closeModal?: Boolean } = {}) => {
    setLoading(true);
    setConfirmationModalOpen(false);
    setOpen(!closeModal);
    if (confirmationFor !== CONFIRMATION_FOR.RE_GENERATE) handleFullCleanUp();
  };

  const handleOnChange = (e: any) => {
    setScript(e.target.value);
  };

  const getRunButtonText = () => {
    switch (executionStatus) {
      case EXECUTION_RESULT.PROVISIONING:
        return 'Cancel execution';
      case EXECUTION_RESULT.RUNNING:
        return 'Cancel execution';
      case EXECUTION_RESULT.PASSED:
        return 'Re-run';
      case EXECUTION_RESULT.FAILED:
        return 'Re-run';
      default:
        return 'Run';
    }
  };

  const handleOnEditSave = async () => {
    notifyInProgress('Saving master automation script');
    const flag: boolean = await editDeleteMAS(
      {
        key: selectedMasterScript?.key as string,
        type: ACTION.EDIT,
        updatedContent: script,
      },
    );
    if (flag) {
      notification.destroy();
      notifySuccess('Master automation script saved successfully!');
    }
  };

  const createNewTestCase = async (): Promise<string> => {
    notifyInProgress('Creating new test case');
    const newTC: any = {
      automationScript: script,
      automationScriptStatus: SCRIPT_STATUS.COMPLETE,
      bddStatus: SCRIPT_STATUS.COMPLETE,
      bdd: 'ENTER YOUR BDD HERE',
      automationScriptType: 'PLAYWRIGHT',
      expectedOutcome: [],
      testData: [],
      scenario: 'Unavailable (Script generated from browser recording)',
      steps: [],
    };
    const newTestCase = await addTestCase(
      requirement.requirementId, requirement.moduleId, newTC,
    );
    setNewTestCase(newTestCase);
    return newTestCase.testcaseId;
  };

  const handleConfirmation = async () => {
    setConfirmationModalOpen(false);
    setLoading(true);
    setExecutionData(undefined);
    switch (confirmationFor) {
      case CONFIRMATION_FOR.TESTCASE:
        if (selectedMasterScript?.script) {
          handleOnEditSave();
        } else if (requirement?.entryType === ENTRY_TYPE.RECORD && !testcase) {
          await createNewTestCase();
          await triggerAutoRefresh();
        } else {
          saveTestCase({
            inputType: selectedScript,
          });
        }
        break;
      case CONFIRMATION_FOR.RE_GENERATE:
        handleCancel({ closeModal: true });
        setOpen(false);
        await reGenerateTCScript(testcase, selectedScript);
        break;
      default:
        break;
    }
  };

  const selectAutomationScript = (value: string) => {
    const isScriptAvailable = addedPreRequisiteScripts
      .find(({ originalScript }) => originalScript === value);
    if (isScriptAvailable) {
      setAddedPreRequisiteScripts((prev: any[]) => prev
        .filter(({ originalScript }) => originalScript !== value));
    } else {
      setAddedPreRequisiteScripts((prev: any[]) => [...prev,
        { script: extractTestBlock(value), step: prev.length + 1, originalScript: value },
      ]);
    }
  };

  const handlePreConditionAddition = () => {
    if (!addedPreRequisiteScripts.length) return;
    let newScript = script;
    addedPreRequisiteScripts.reverse().forEach((aPRScript) => {
      newScript = mergeTestBlocks(aPRScript.script, newScript);
    });
    form.setFieldValue('automationScript', newScript);
    setScript(newScript);
    setAddedPreRequisiteScripts([]);
  };

  const importCTR = (file: CommonResource, method: string) => {
    let importStatement: string = '';
    switch (method) {
      case CTR_IMPORT_TYPE.OBJECT:
        importStatement = `const ${toCamelCase(file.name.split('.')[0])} = require('./${file.path}');`;
        break;
      case CTR_IMPORT_TYPE.PATH:
        importStatement = `const ${toCamelCase(file.name.split('.')[0])} = './${file.path}';`;
        break;
      default:
        break;
    }
    setScript(`${importStatement}\n ${script}`);
    form.setFieldValue('automationScript', `${importStatement}\n ${script}`);
    setTimeout(() => {
      setShowCTRNotification(true);
    }, 1000);
  };

  const fetchExecutionData = async (testcaseId: string,
    executionId: string,
    exStatus: string) => {
    const res = await getExecution(testcaseId, executionId);
    setExecutionStatus((exStatus === EXECUTION_RESULT.COMPLETED
      && !res?.log?.suites[0]?.specs[0]?.tests) ? EXECUTION_RESULT.FAILED : exStatus);
    if (res) {
      setExecutionData(res);
      return typeof res.videoUrl === 'string';
    }
    return false;
  };

  const setIDEData = async () => {
    if (testcase) {
      if (testcase?.executionStatus) {
        if (testcase?.executionStatus === EXECUTION_RESULT.RUNNING
          && executionStatus === EXECUTION_RESULT.PROVISIONING) {
          setExecutionStatus(EXECUTION_RESULT.RUNNING);
        } else if (testcase?.executionStatus === EXECUTION_RESULT.COMPLETED
          && executionStatus === EXECUTION_RESULT.RUNNING) {
          setExecutionStatus(EXECUTION_RESULT.COMPLETED);
        } else if (executionStatus
          && testcase?.executionStatus === EXECUTION_RESULT.CANCELED
          && [EXECUTION_RESULT.PROVISIONING, EXECUTION_RESULT.RUNNING].includes(executionStatus)) {
          setExecutionStatus(EXECUTION_RESULT.CANCELED);
        } else if (!executionStatus) {
          setExecutionStatus(testcase?.executionStatus);
        }
      } else {
        setExecutionStatus(undefined);
        setExecutionData(undefined);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const isExecuting: boolean = selectedScript === SCRIPT_TYPE.AUTOMATION_SCRIPT
    && (executionStatus === EXECUTION_RESULT.RUNNING
      || executionStatus === EXECUTION_RESULT.PROVISIONING);

  const handleExecution = async () => {
    if (isExecuting) {
      setExecutionData(undefined);
      setExecutionStatus(EXECUTION_RESULT.PROVISIONING);
      await cancelExecutionScript(
        testcase?.testcaseId,
        testcase?.executionId,
        selectedMasterScript,
      );
    } else {
      if (selectedMasterScript?.script) {
        setExecutionData(undefined);
        setExecutionStatus(EXECUTION_RESULT.PROVISIONING);
        await handleOnEditSave();
      }
      setEDSModel(true);
      notification.destroy();
    }
  };

  const handlePasteFromClipboard = () => {
    navigator.clipboard.readText().then((clipboardText: string) => {
      setScript(clipboardText);
      form.setFieldValue('automationScript', clipboardText);
    });
  };

  const getConfirmationBody = () => {
    switch (confirmationFor) {
      case CONFIRMATION_FOR.TESTCASE:
        if (form.getFieldValue('testcaseId')) {
          return 'You are attempting to edit a test case generated by footprint. Please note that this action is permanent and cannot be undone. Are you sure you want to proceed with editing this test case?';
        }
        return 'Are you sure you want to create this testcase';
      case CONFIRMATION_FOR.RE_GENERATE:
        return 'Are you sure you want to regenerate the script? If you proceed, the existing script will be replaced with a new one, and all your previous changes will be lost';
      default:
        return '';
    }
  };

  const handleFinalExecution = async (selectedEDB: any) => {
    let testcaseId: string | undefined = testcase?.testcaseId;
    setExecutionData(undefined);
    setExecutionStatus(EXECUTION_RESULT.PROVISIONING);
    if (requirement?.entryType === ENTRY_TYPE.RECORD
      && !testcase) {
      testcaseId = await createNewTestCase();
    } else {
      await saveTestCase({
        skipCleanUp: true,
        inputType: selectedScript,
      });
    }
    await runAutomationScript(
      testcaseId,
      selectedMasterScript,
      script,
      selectedEDB,
    );
  };

  const loadExecution = async () => {
    setLoading(true);
    await fetchExecutionData(
      testcase?.testcaseId, testcase?.executionId, testcase?.executionStatus,
    );
    setLoading(false);
  };

  useEffect(() => {
    if (testcase?.executionId
      && executionStatus) {
      if ([EXECUTION_RESULT.PROVISIONING, EXECUTION_RESULT.RUNNING].includes(executionStatus)) {
        setLoading(false);
      } else if (
        [EXECUTION_RESULT.COMPLETED, EXECUTION_RESULT.CANCELED].includes(executionStatus)) {
        loadExecution();
      }
    }
  }, [executionStatus]);

  useEffect(() => {
    if (executionStatus && testcase?.isLiveTestRun) {
      if ([EXECUTION_RESULT.PROVISIONING, EXECUTION_RESULT.RUNNING].includes(executionStatus)) {
        setScriptRunning(true);
      } else {
        setScriptRunning(false);
      }
    }
  }, [executionStatus, testcase]);

  useEffect(() => {
    if (open) {
      if (testcase?.bdd && selectedScript) {
        setScript(selectedScript === SCRIPT_TYPE.BDD ? testcase?.bdd : testcase.automationScript);
      }
    }
  }, [open, selectedScript]);

  useMemo(() => {
    if (open) setIDEData();
  }, [testcase, open]);

  const renderIDE = () => {
    if (!script && requirement?.entryType === ENTRY_TYPE.RECORD) {
      return (
        <div className="testcase_copy_paste_container">
          <Button
            type="primary"
            className="fp_btn"
            onClick={handlePasteFromClipboard}
            icon={<PasteIcon className="paste_icon" />}
          >
            Paste from Clipboard
          </Button>
        </div>
      );
    }
    if (isScriptRunning && testcase?.liveServerAddress && testcase?.isLiveTestRun) {
      return (
        <div className="testcase_copy_paste_container" style={{ height: 800 }}>
          <iframe
            src={`${testcase?.liveServerAddress}:6080/vnc_lite.html?resize=true`}
            title="live test execution"
            width="100%"
            height="100%"
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          />
        </div>
      );
    }
    if (selectedScript === SCRIPT_TYPE.BDD) {
      return (
        <>
          <EditorIDE
            content={script}
            form={form}
            handleOnChange={handleOnChange}
            selectedScript={selectedScript}
          />
          <Button
            type="primary"
            className="fp_btn fp_btn-primary"
            onClick={() => triggerConfirmationModal(CONFIRMATION_FOR.RE_GENERATE)}
            style={{ marginBottom: 15, marginTop: 5 }}
          >
            Re-Generate
          </Button>
        </>
      );
    }
    return (
      <IDETabMenu
        selectedMasterScript={selectedMasterScript}
        executionData={executionData}
        script={script}
        handleOnChange={handleOnChange}
        testcase={testcase}
        testcases={testcases}
        addedPreRequisiteScripts={addedPreRequisiteScripts}
        selectAutomationScript={selectAutomationScript}
        selectedScript={selectedScript}
        handlePreConditionAddition={handlePreConditionAddition}
        form={form}
        setCTROpenModal={setCTROpenModal}
      />
    );
  };

  const TitleComponent = () => (
    <div style={{ marginBottom: '0' }} className="fp_extension_instructions_modal_header">
      <SmallLogo width={32} height={29} />
      <p>
        Extension Instructions here
      </p>
    </div>
  );

  return (
    <div>
      <Modal
        className="max-user-modal fp-modal fp-modal-primary ide-modal"
        width="75%"
        open={open}
        onCancel={() => handleCancel({ closeModal: true })}
        footer={isLoading ? [] : [
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="primary" className="fp_btn fp_btn-outline" onClick={() => handleCancel({ closeModal: true })}>Discard</Button>
            {(selectedScript === SCRIPT_TYPE.AUTOMATION_SCRIPT
              || selectedMasterScript?.script) && (
                <Button
                  type="primary"
                  className="fp_btn fp_btn-primary"
                  onClick={handleExecution}
                  disabled={!script}
                  icon={executionStatus === EXECUTION_RESULT.PROVISIONING
                    || executionStatus === EXECUTION_RESULT.RUNNING
                    ? <PauseCircleOutlined /> : <CaretRightOutlined />}
                >
                  {getRunButtonText()}
                </Button>
            )}
            <Button type="primary" className="fp_btn fp_btn-primary" onClick={() => triggerConfirmationModal(CONFIRMATION_FOR.TESTCASE)} disabled={isExecuting || !script}>Save</Button>
          </div>,
          <div>
            {requirement?.entryType === ENTRY_TYPE.RECORD && (
              <Collapse bordered={false} expandIconPosition="end">
                <Panel header={<TitleComponent />} key="1">
                  <ExtensionInstructionContent showTitle={false} />
                </Panel>
              </Collapse>
            )}
          </div>,
        ]}
      >
        {isLoading ? <Loading plain /> : (
          <div>
            <div>
              <Row>
                <Col span={20}>
                  <Title
                    level={5}
                    style={{ color: 'white' }}
                  >
                    {`Test Case in ${selectedScript === SCRIPT_TYPE.BDD
                      ? 'BDD Format' : 'Automation script Format'} `}
                  </Title>
                  <p style={{ marginBottom: 20 }}>
                    {requirement?.requirementDescription
                      || requirement?.requirement}
                  </p>
                </Col>
                {(selectedScript === SCRIPT_TYPE.AUTOMATION_SCRIPT
                  || selectedMasterScript?.executionId) && executionStatus && (
                    <Col span={4}>
                      <div className="fp_testcase_test_status">
                        <StatusView
                          status={executionStatus}
                          executionResults={executionData?.log?.suites[0]?.specs[0]?.tests}
                          passPercentage={executionData?.passPercentage}
                        />
                      </div>
                    </Col>
                )}
              </Row>
            </div>
            {renderIDE()}
            {selectedScript === SCRIPT_TYPE.AUTOMATION_SCRIPT && (
              <p>
                Please note: Our automation scripts are now generated
                in the format compatible with Playwright&apos;s new test runner.
                This runner provides better organization, isolated execution,
                and superior debugging features for your test scripts.
                The scripts you generate with this tool will be ready to use
                with Playwright&apos;s new test runner directly.
              </p>
            )}
          </div>
        )}
      </Modal>
      <ConfirmationModal
        open={isConfirmationModalOpen}
        title={confirmationFor === CONFIRMATION_FOR.TESTCASE ? 'Are you sure you want to edit this testcase' : 'Are you sure you want to regenerate'}
        body={getConfirmationBody()}
        onCancel={() => (confirmationFor === CONFIRMATION_FOR.TESTCASE
          ? handleCancel() : handleCancel({ closeModal: false }))}
        onProceed={() => handleConfirmation()}
      />

      <CTRModal
        open={isCTRModalOpen}
        onClose={setCTROpenModal}
        projectId={projectId}
        projectName={projectName}
        selectFile={importCTR}
        importMode
      />

      <PopUpNotification
        open={showCTRNotification}
        message={'We noticed you\'ve imported an external script file to enhance your test script. While the code has been updated, we strongly recommend revisiting the "Preconditions," "Steps," and "Expected Results" sections to reflect these changes.Maintaining your automation scripts in natural language makes them more accessible and easier to understand. Please consider updating these fields to ensure that your test scripts remain clear and comprehensible for all team members.'}
        onOkay={() => setShowCTRNotification(false)}
        name="ctr-model-pop-up-notification"
        title=""
      />

      <ExecutionDeviceSelectionModal
        open={openEDSmodel}
        onClose={setEDSModel}
        onRun={handleFinalExecution}
        buttonName="Run"
      />
    </div>
  );
};

export default IDEModal;
