import { API } from 'aws-amplify';
import { notification } from 'antd';
import { ProjectInput, setProjects } from '../../redux/projectSlice';
import { AppDispatch } from '../../redux/store';
import { APIS } from '../../utils/apiUtils';
import { setTestCases } from '../../redux/testCaseSlice';
import { notifyError, notifyInProgress, notifySuccess } from '../../utils/notification';

export const getProjects = async (accountId: string|undefined, dispatch: AppDispatch) => {
  const { projects } = await API.get(APIS.PROJECT_API, '/getProjects', { queryStringParameters: { accountId } });
  dispatch(setProjects(projects));
};

export const createProject = async (project: ProjectInput) => {
  const { projectId } = await API.post(APIS.PROJECT_API, '/create', { body: project });
  return projectId;
};

export const createModule = async (module: any) => {
  const { moduleId } = await API.post(APIS.PROJECT_API, '/module/create', { body: module });
  return moduleId;
};

export const createRequirement = async (requirement: any) => {
  const { requirementId } = await API.post(APIS.PROJECT_API, '/module/requirement/create', { body: requirement });
  return requirementId;
};

export const deleteProject = async (accountId: string, projectId: string,
  dispatch: AppDispatch): Promise<boolean> => {
  try {
    await API.del(APIS.PROJECT_API, '/delete', { queryStringParameters: { accountId, projectId } });
    await getProjects(accountId, dispatch);
    return true;
  } catch (err: any) {
    console.log(err?.message);
    return false;
  }
};

export const deleteRequirementAPI = async (
  moduleId: string,
  requirementId: string,
): Promise<boolean> => API.del(APIS.PROJECT_API, '/module/requirement/delete', { queryStringParameters: { moduleId, requirementId } });

export const deleteModule = async (
  accountId: string, projectId: string, moduleId: string,
): Promise<boolean> => {
  try {
    await API.del(APIS.PROJECT_API, '/module/delete', { queryStringParameters: { accountId, projectId, moduleId } });
    return true;
  } catch (err: any) {
    console.log(err?.message);
    return false;
  }
};

// Test Case Actions

export const getTestCases = async (projectId: string|undefined, dispatch: AppDispatch) => {
  const { testCases = [] } = await API.get(APIS.TEST_CASE_API, '/get-all', { queryStringParameters: { projectId } });
  dispatch(setTestCases(testCases));
};

export const deleteTestCases = async (accountId: string, projectId: string,
  testcaseId: string,
  dispatch: AppDispatch) => {
  const { testCases = [] } = await API.del(APIS.TEST_CASE_API, '/delete', { queryStringParameters: { accountId, projectId, testcaseId } });
  dispatch(setTestCases(testCases));
};

export const getGeneratedTestcases = async (moduleId: string|undefined) => {
  try {
    return await API.get(APIS.TESTCASE_CREATING_API, '/requirements', { queryStringParameters: { moduleId } });
  } catch (error: any) {
    throw new Error('No result found');
  }
};

export const addEditProjectTestData = async (
  accountId: string, projectId: string, globalTestData: any,
) => {
  try {
    notifyInProgress('Saving global test data');
    await API.post(APIS.PROJECT_API, '/test-data/create', {
      body: {
        accountId, projectId, globalTestData,
      },
    });
    notification.destroy();
    notifySuccess('Global test data saved successfully!');
  } catch (error) {
    notification.destroy();
    notifyError('Unable to save global test data');
  }
};

export const getGlobalTestData = async (
  accountId: string, projectId: string,
) => {
  try {
    return await API.get(APIS.PROJECT_API, '/test-data', {
      queryStringParameters: {
        accountId, projectId,
      },
    });
  } catch (error) {
    notification.destroy();
    notifyError('Unable to fetch global test data');
    return { globalTestData: [] };
  }
};

export const getPreSignedURLForCTR = (
  key: string,
  contentType: string | undefined,
  method: string | undefined,
) => API.get(
  APIS.TESTCASE_CREATING_API,
  '/testcase/common-test-resources/get-signed-url',
  { queryStringParameters: { key, contentType, method } },
);

export const updateProject = async (
  accountId: string, projectId: string, updatedPayload: any,
) => API.post(APIS.PROJECT_API, '/update-project', { queryStringParameters: { accountId, projectId }, body: updatedPayload });

export const deleteProjectCTR = async (key: string) => API.get(APIS.TESTCASE_CREATING_API, '/testcase/common-test-resources/delete', { queryStringParameters: { key } });
