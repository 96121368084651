import React, { useEffect, useState } from 'react';
import {
  Button, Col, Form, Row,
  notification,
} from 'antd';
import moment from 'moment';
import { ResumeIcon, PauseIcon } from '../../components';
import ExecutionDeviceSelectionModal from '../testcaseCreation/components/testCaseCreatedResult/modals/ExecutionDeviceSelectionModal';
import ExecutionMonitoringModal from '../testcaseCreation/components/testCaseCreatedResult/modals/ExecutionMonitoringModal';
import { updateMonitorJob, disableMonitor } from './action';
import { notifyInProgress, notifySuccess, notifyError } from '../../utils/notification';
import { PAUSE_RESUME_BUTTON, SCHEDULE_STATE, MONITOR_STATUS } from '../../types/common';

const MonitoringScheduleItem = ({ job }: any) => {
  const [buttonValue, setButtonValue] = useState<string>(PAUSE_RESUME_BUTTON.PAUSE);
  const [isEMModal, setEMModal] = useState<boolean>(false);
  const [isEDSModal, setEDSModal] = useState<boolean>(false);
  const [isButtonActionLoading, setButtonActionLoading] = useState<boolean>(false);

  const [form] = Form.useForm();

  const handleButtonAction = async () => {
    if (buttonValue === PAUSE_RESUME_BUTTON.PAUSE) {
      setButtonActionLoading(true);
      await disableMonitor({
        accountId: job.accountId,
        monitorId: job.monitorId,
      });
      setButtonValue(PAUSE_RESUME_BUTTON.RESUME);
      setButtonActionLoading(false);
    } else {
      setEMModal(true);
    }
  };

  const setFormData = () => {
    const fData = {
      fromdate: moment(job?.startDate),
      starttime: moment(job?.startDate),
      todate: moment(job?.endDate),
      endTime: moment(job?.endDate),
      frequency: job?.repeatFrequency,
      email: job?.failAlertNotifier,
    };
    form.setFieldsValue(fData);
    if (job.status === MONITOR_STATUS.IN_PROGRESS) {
      setButtonValue(PAUSE_RESUME_BUTTON.PAUSE);
    } else if (job.status === MONITOR_STATUS.PAUSED) {
      setButtonValue(PAUSE_RESUME_BUTTON.RESUME);
    }
  };

  const updateMonitor = async (selectedEDB: any) => {
    try {
      setButtonActionLoading(true);
      notifyInProgress('Updating the monitor');
      const fData = form.getFieldsValue();

      const startDate = moment({
        year: fData.fromdate.year(),
        month: fData.fromdate.month(),
        date: fData.fromdate.date(),
        hour: fData.starttime.hours(),
        minute: fData.starttime.minutes(),
        second: fData.starttime.seconds(),
      });

      const endDate = moment({
        year: fData.todate.year(),
        month: fData.todate.month(),
        date: fData.todate.date(),
        hour: fData.endTime.hours(),
        minute: fData.endTime.minutes(),
        second: fData.endTime.seconds(),
      });

      const queryParameters = {
        accountId: job.accountId,
        monitorId: job.monitorId,
        status: SCHEDULE_STATE.ENABLED,
      };

      const payload = {
        startDate,
        endDate,
        repeatFrequency: fData.frequency,
        failAlertNotifier: fData.email,
        selectedEDB,
      };

      await updateMonitorJob(queryParameters, payload);
      notification.destroy();
      notifySuccess('Monitor updated successfully!');
      setButtonValue(PAUSE_RESUME_BUTTON.PAUSE);
    } catch (err) {
      notifyError('Failed to resume monitor');
      console.log(err);
    }
    setButtonActionLoading(false);
  };

  useEffect(() => {
    if (job) {
      setFormData();
    }
  }, [job]);

  return (
    <div className="fp_test_exec_list_item">
      <Row justify="start" className="fp_list_item_new">
        <Col span={4} className="right-border"><p>{`Every ${job.repeatFrequency}`}</p></Col>
        <Col span={10} className="fp_list_item_module column-align right-border">
          <p>{moment(job?.startDate).format('lll')}</p>
          <span className="dash-icon">-</span>
          <p>{moment(job?.endDate).format('lll')}</p>
        </Col>
        <Col span={5} className="column-align right-border">
          <p>{moment(job?.lastRun).format('lll')}</p>
        </Col>
        {job.status === MONITOR_STATUS.COMPLETE ? (
          <Col span={4} className="column-align">
            <p style={{ color: 'rgba(34, 169, 134, 1)' }}>{job.status}</p>
          </Col>
        ) : (
          <Col span={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={isButtonActionLoading}
              loading={isButtonActionLoading}
              type="primary"
              className={`fp_btn fp_btn-view ${buttonValue === PAUSE_RESUME_BUTTON.PAUSE ? 'fp_btn-view-pause_button' : 'fp_btn-view-resume_button'}`}
              icon={buttonValue === PAUSE_RESUME_BUTTON.PAUSE ? <PauseIcon /> : <ResumeIcon />}
              onClick={handleButtonAction}
            >
              {buttonValue}
            </Button>
          </Col>
        )}
      </Row>
      <ExecutionDeviceSelectionModal
        open={isEDSModal}
        onRun={updateMonitor}
        onClose={() => {
          setEDSModal(false);
        }}
        buttonName="Monitor"
      />
      <ExecutionMonitoringModal
        open={isEMModal}
        form={form}
        onClose={(flag: boolean) => {
          setEMModal(false);
          if (flag) {
            setEDSModal(true);
          }
        }}
      />
    </div>
  );
};

export default MonitoringScheduleItem;
