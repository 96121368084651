import { Col, Row } from 'antd';
import React from 'react';
import Loading from '../../../../components/Loading';
import FrontendLogsListItem from './FrontendLogsListItem';

interface propType {
  isLoading: boolean
  frontendLogs: any[]
  selectedExecution: Function
}

const FrontendLogList = ({ isLoading = true, frontendLogs, selectedExecution }: propType) => (
  <div className="fp_list" style={{ marginTop: '0px' }}>
    <Row justify="start" className="fp_list_title_new">
      <Col span={6} className="column-align right-border"><p>Date Time</p></Col>
      <Col span={14} className="column-align right-border"><p>Console logs</p></Col>
      <Col span={4} className="column-align"><p>Details</p></Col>
    </Row>
    {isLoading ? <Loading plain size="large" /> : frontendLogs?.map((log: any) => (
      <FrontendLogsListItem
        log={log}
        selectedExecution={selectedExecution}
      />
    ))}
  </div>
);

export default FrontendLogList;
